import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import teamLogo from "../img/saleswoman 1.png";
import team from "../img/drug_test_118320558web-min 1.png";
import homegroupLogo from "../img/homegroupLogo.png";
import SidebarLayout from "./SidebarLayout";
import SidebarBox from "./SidebarBox";

function BuildIntegratedBusinessProcess() {
  return (
    <>
      <div className="p-0">
        <div className="row ">
          <div className="col-6 " style={{ color: "#203AAB" }}>
            <div
              style={{
                color: "#203AAB",
                fontSize: "50px",
                fontWeight: "bold",
                padding: "1.5em",
                // paddingTop:'4em'
              }}
            >
              Build an integrated business process.
            </div>
            <img
              src={homegroupLogo}
              alt=""
              style={{ width: "98%", marginTop: "0px" }}
            />
          </div>
          {/* <div className="col-6 ">
            <div  >
           <SidebarLayout/>

            </div>
            <div style={{}} className="text-center">
                <button style={{color:'#203AAB',fontWeight:'bold',backgroundColor:'#F7921E',border:'none',padding:'1em',borderRadius:'5px',marginTop:'1.2em'}}>
                Request a FREE Trial
                </button>
            </div>
          </div> */}

          <div className="col-6">
            <div>
              <SidebarBox/>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BuildIntegratedBusinessProcess;
