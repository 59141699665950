import React, { useState } from "react";
import image from "../img/image 1.png";

const styles = {
  sidebarBox: {
    marginTop: '3.5em',
  },
  tabsContainer: {
    display: 'flex',
    border: '6px solid #203AAB',
    marginRight: '6em',
    borderRadius: '22px',
  },
  tabs: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    padding: '1em',
    fontSize: '24px',
    color: '#92A1E3',
    cursor: 'pointer',
  },
  tab: (isActive) => ({
    padding: '0.5em',
    borderRadius: '5px',
    transition: 'background-color 0.3s',
    backgroundColor: isActive ? 'transparent' : 'transparent',
    color: isActive ? '#203AAB' : '#92A1E3',
    fontWeight: isActive ? 'bold' : 'normal',
  }),
  imageContainer: {
    backgroundColor: '#ccc',
    borderRadius: '0 22px 22px 0',
    width: '100%',
  },
  image: {
    width: '100%',
  },
  trialButton: {
    color: '#203AAB',
    backgroundColor: '#F7921E',
    border: 'none',
    padding: '1em 1.2em',
    fontWeight: 'bold',
    cursor: 'pointer',
  },
  textCenter: {
    marginTop: '2em',
    textAlign: 'center',
  },
};

const SidebarBox = () => {
  const [activeTab, setActiveTab] = useState('Sales & Marketing');

  const tabs = [
    'Sales & Marketing',
    'Email & Collaboration',
    'Human Resources',
    'Custom Solutions',
    'IT & Helpdesk',
    'Finance'
  ];

  return (
    <div style={styles.sidebarBox}>
      <div style={styles.tabsContainer}>
        <div style={styles.tabs}>
          {tabs.map(tab => (
            <div
              key={tab}
              style={styles.tab(activeTab === tab)}
              onClick={() => setActiveTab(tab)}
            >
              {tab}
            </div>
          ))}
        </div>
        <div style={styles.imageContainer}>
          <img src={image} alt="Sidebar" style={styles.image} />
        </div>
      </div>
      <div style={styles.textCenter}>
        <button style={styles.trialButton}>
          Request a FREE Trial
        </button>
      </div>
    </div>
  );
};

export default SidebarBox;
